import {UploadFile} from "antd";
import {useUploadFile} from "components/Organisms/uploads/api";
import {END_POINTS} from "configs/endpoints";
import React from "react";
import {useHttp} from "utils/http";

export const useReviseQuotation = (taskId) => {
  const client = useHttp();
  const { mutate: upload } = useUploadFile();

  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const uploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res: File = await upload(formData);
      return res;
    } catch (err) {
      const error = new Error("Some error");
      throw error;
    }
  };

  const uploadFileWithType = (type) => async (files) => {
    const tasks = files.map(async (file) => {
      if (file.originFileObj) {
        const uploaded: {
          uuid: string;
          fileName: string;
          size: number;
          mimetype: string;
        } = (await uploadFile(file.originFileObj)) as any;

        return {
          type,
          uuid: uploaded.uuid,
          fileName: uploaded.fileName,
          mimetype: uploaded.mimetype,
          size: uploaded.size,
        };
      }
      return Promise.resolve({ ...file, type });
    });
    return Promise.all(tasks);
  };

  const reviseQuotation = async (
    additionalTestQuotationId: string,
    report: {
      reviseReason: string;
      remark: string;
      testReportFiles: UploadFile[];
    }
  ) => {
    setLoading(true);
    const { testReportFiles } = report;
    const uploadedFiles = await uploadFileWithType("test_report")(
      testReportFiles || []
    );
    const response = await client(
      `${END_POINTS.tasks}/${taskId}/compliance/request-additional-test/revise-quotation/${additionalTestQuotationId}`,
      {
        method: "POST",
        data: {
          ...report,
          testReportFiles: uploadedFiles,
        },
      }
    );

    setData(response);
    setLoading(false);
    return response;
  };
  return {
    data,
    reviseQuotation,
    loading,
  };
};

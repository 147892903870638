import React from "react";
import {Form, FormProps} from "antd";

const CusForm = (props: Partial<FormProps>) => {
  return (
    <Form {...props} layout="vertical">
      {props.children as any}
    </Form>
  );
};

export default CusForm;

import React from "react";
import {Form, Input, Select} from "antd";
// import cnFlag from "assets/country-flag/china.png";
// import hkFlag from "assets/country-flag/hong-kong.png";
import styled from "@emotion/styled";
import {getCountriesWithFlags} from "fixtures/countries";

const { Option } = Select;

const StyledInput = styled(Input)`
  .ant-input-group-addon {
    .ant-form-item {
      margin-bottom: 0px;
      .ant-form-item-control-input {
        min-height: auto;
        height: 100%;
      }
    }
    .ant-select {
      min-width: 95px;
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const Countries = getCountriesWithFlags();
const PhoneInput = ({ selectProps, inputProps, group = false }: any) => {
  const { formProps, ...restSelect } = selectProps;
  const { formProps: inputFormProps, ...restInput } = inputProps;
  if (group) {
    return (
      <StyledFormItem {...inputFormProps}>
        <StyledInput
          {...restInput}
          addonBefore={
            <Form.Item {...formProps}>
              <Select {...restSelect}>
                {Countries.map(({ phone, flag, name }) => {
                  return (
                    <Option value={`+${phone[0]}`} key={name}>
                      <span role="img" aria-label="China">
                        {flag}
                      </span>
                      {` +${phone[0]}`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          }
        />
      </StyledFormItem>
    );
  }
  return (
    <Input.Group compact>
      <Select defaultValue="+86" style={{ width: "45%" }}>
        {Countries.map(({ phone, flag, name }) => {
          return (
            <Option value={`+${phone[0]}`} key={name}>
              <span role="img" aria-label="China">
                {flag}
              </span>
              {` +${phone[0]}`}
            </Option>
          );
        })}
      </Select>
      <Input style={{ width: "55%" }} defaultValue="Xihu District, Hangzhou" />
    </Input.Group>
  );
};

export default PhoneInput;

import {parserDocument, parserRejectReason} from "configs/parsers";
import {isNil, isString} from "lodash";
import get from "lodash/get";

const baseParser = (name) => (data) => {
  const value = get(data, name);
  return value;
};

const baseParserSelection = (name) => (data) => {
  const value = baseParser(name)(data);

  if (Array.isArray(value) && !!value.length) return value[0];
  if (isString(value)) return value;
  return null;
};

export const COMPLIANCE_CHECK_MAPPINGS = [
  ["id", "id", baseParser, baseParser],
  [
    "isDrafted",
    "isDrafted",
    (name) => (data) => {
      const value = get(data, name);
      if (isNil(value)) return true;
      return value;
    },
    null,
  ],
  [
    "taskType",
    "task",
    (name) => (data) => {
      const value = get(data, name);
      if (isNil(value)) return true;
      return value.taskType;
    },
    null,

  ],
  //#region TestPlan
  ["testPlan.documents", "testPlan", parserDocument, baseParser],
  //#endregion
  //#region TEST REPORT
  ["testReport.remarks", "testReportRemark", baseParser, baseParser],
  ["testReport.documents", "testReport", parserDocument, baseParser],
  [
    "testReport.reviewer.isReuploadTestReport",
    "reviewer.isReuploadTestReport",
    (name) => (data) => {
      const value = get(data, name);
      if (!!value) return ["required"];
      return false;
    },
    (name) => (data) => {
      const value = get(data, name);
      return Array.isArray(value) ? value.includes("required") : false;
    },
  ],
  [
    "testReport.reviewer.remarks",
    "reviewer.reviewerTestReportRemark",
    baseParser,
    baseParser,
  ],
  [
    "testReport.reviewer.documents",
    "reviewerTestReportDocuments",
    () => () => [],
    baseParser,
  ],
  ["productSpecificationLabellingArtworkPackagingArtwork.remarks", "productSpecificationLabellingArtworkPackagingArtworkRemark", baseParser, baseParser],
  ["productSpecificationLabellingArtworkPackagingArtwork.documents", "productSpecificationLabellingArtworkPackagingArtwork", parserDocument, baseParser],
  [
    "productSpecificationLabellingArtworkPackagingArtwork.reviewer.isReuploadProductSpecificationLabellingArtworkPackagingArtwork",
    "reviewer.isReuploadProductSpecificationLabellingArtworkPackagingArtwork",
    (name) => (data) => {
      const value = get(data, name);
      if (!!value) return ["required"];
      return false;
    },
    (name) => (data) => {
      const value = get(data, name);
      return Array.isArray(value) ? value.includes("required") : false;
    },
  ],
  [
    "productSpecificationLabellingArtworkPackagingArtwork.reviewer.remarks",
    "reviewer.reviewerProductSpecificationLabellingArtworkPackagingArtworkRemark",
    baseParser,
    baseParser,
  ],
  [
    "productSpecificationLabellingArtworkPackagingArtwork.reviewer.documents",
    "reviewerProductSpecificationLabellingArtworkPackagingArtworkDocuments",
    () => () => [],
    baseParser,
  ],
  //#endregion TEST REPORT
  //#region ProductImage
  ["productImage.remarks", "productImageRemark", baseParser, baseParser],
  ["productImage.documents", "productImages", parserDocument, baseParser],
  [
    "productImage.reviewer.isReuploadProductImage",
    "reviewer.isReuploadProductImage",
    (name) => (data) => {
      const value = get(data, name);
      if (!!value) return ["required"];
      return false;
    },
    (name) => (data) => {
      const value = get(data, name);
      return Array.isArray(value) ? value.includes("required") : false;
    },
  ],
  [
    "productImage.reviewer.remarks",
    "reviewer.reviewerProductImageRemark",
    baseParser,
    baseParser,
  ],
  [
    "productImage.reviewer.documents",
    "reviewerProductImageDocuments",
    () => () => [],
    baseParser,
  ],
  //#endregion
  //#region Declaration of conformity (conformityCertificate)
  [
    "conformityCertificate.remarks",
    "conformityCertificateRemark",
    baseParser,
    baseParser,
  ],
  [
    "conformityCertificate.documents",
    "conformityCertificate",
    parserDocument,
    baseParser,
  ],
  [
    "conformityCertificate.reviewer.remarks",
    "reviewer.reviewerConformityCertificateRemark",
    baseParser,
    baseParser,
  ],

  [
    "conformityCertificate.conformityCertificateSelection",
    "conformityCertificateSelection",
    baseParser,
    baseParserSelection,
  ],
  [
    "conformityCertificate.reviewer.conformityCertificateType",
    "conformityCertificateType",
    baseParser,
    (name) => (data) => {
      const value = get(data, name);
      return value?.value || value;
    },
  ],
  ["cpcTestLines", "testLines.cpcTestLines", baseParser, baseParser],
  ["docTestLines", "testLines.docTestLines", baseParser, baseParser],
  [
    "conformityCertificate.reviewer.reportData",
    "testLines.reportDataTestLines",
    baseParser,
    (name) => (data) => {
      const value = get(data, name);
      return Array.isArray(value)
        ? value.map(
            ({ country, citation, testItem, legislation, ...rest }: any) => {
              const willUpdate = {
                ...rest,
                citation: { name: citation },
                testItem: { name: testItem },
              };
              if (willUpdate.id.includes("new")) {
                delete willUpdate.id;
              }
              return willUpdate;
            }
          )
        : [];
    },
  ],
  [
    "conformityCertificate.reviewer.documents",
    "reviewerConformityDocuments",
    () => () => [],
    baseParser,
  ],
  [
    //#endregion
    //#region Reject REASONS
    "rejectReasons",
    "rejectReasons",
    parserRejectReason,
    null,
  ],
  //#endregion
];

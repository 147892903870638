import React from "react";
import {Divider as AntdDivider} from "antd";
import {IBaseField, IDivider} from "../FormRenderer/type";
import {pick} from "lodash";

type IDividerProps = {
  field: IDivider<IBaseField>;
};

const Divider: React.FC<IDividerProps> = ({ field }) => {
  const dividerProps = React.useMemo(() => {
    return pick(field, ["dashed", "plain", "dashed", "dividerType"]);
  }, [field]);
  return (
    <AntdDivider
      {...dividerProps}
      type={field.dividerType === "vertical" ? "vertical" : "horizontal"}
    ></AntdDivider>
  );
};

export default Divider;

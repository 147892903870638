import React from "react";
import {useHttp} from "utils/http";

export const usePromoteCode = (promoteCode) => {
  const [isValid, setIsValid] = React.useState(false);
  const [isApplied, setIsApplied] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const client = useHttp();
  // const { run } = useAsync<any>();
  const verifyCode = React.useCallback(
    () =>
      client(`promote-codes/validate`, {
        method: "POST",
        data: {
          code: promoteCode,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [promoteCode]
  );

  const verify = async () => {
    try {
      const response = await verifyCode();
      const { valid } = response;
      setIsValid(valid);
      if (!valid) {
        setError("Your promote code is not valid");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setError("Your promote code is not valid");
    }
  };

  const clear = () => {
    setIsValid(false);
    setIsApplied(false);
    setLoading(false);
    // setError("");
  };

  return {
    isValid,
    isApplied,
    isLoading,
    error,
    verify,
    clear,
  };
};

import {ColProps} from "antd";
import {FormInstance, FormListOperation, Rule} from "antd/lib/form";
import {NamePath} from "antd/lib/form/interface";
import {ShowUploadListInterface, UploadListType, UploadType,} from "antd/lib/upload/interface";
import React from "react";

export enum EnumFieldType {
  "TEXTAREA" = "TEXTAREA",
  "NUMBER" = "NUMBER",
  "SELECT" = "SELECT",
  "CHECKBOX" = "CHECKBOX",
  "RADIO" = "RADIO",
  "UPLOAD" = "UPLOAD",
  "DERIVED_TEXT" = "DERIVED_TEXT",
  "LIST" = "LIST",
  //LAYOUT
  "SECTION" = "SECTION",
  //NON INPUT
  "DIVIDER" = "DIVIDER",
  //CUSTOM
  "CUSTOMIZED" = "CUSTOMIZED",
}

type Option = {
  value: string;
  label: string | React.ReactNode;
  showWhen?: (
    field: IBaseField,
    option: Option,
    value: any,
    formValue: any
  ) => boolean;
};

export type IBaseField = {
  //#region Common
  fieldId: string;
  label: string | React.ReactNode;
  name: string | string[];
  //Not yet implement
  showWhen?: (field: IBaseField, value: any, formValue: any) => boolean;
  //Not yet implement
  shouldDisabled?: (field: IBaseField, value: any, formValue: any) => boolean;
  shouldHiddenRemove?: (field: IBaseField, value: any, formValue: any) => boolean;
  rules?: Rule[];
  required?: boolean;
  sectionName: string;
  bordered?: boolean;
  defaultValue?: string | number | Record<string, any> | any[] | unknown;
  placeholder?: string | React.ReactNode;
  type: EnumFieldType;
  col?: ColProps;
  readonly?: boolean;
  cannotRemove?: boolean;
  dependencies:NamePath;
  //#endregion

  formatter?: (field: IBaseField, value: any, formValue: any) => {};
  parser?: () => {};
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;

  //#region FootNote
  //Not yet implement
  footNoteShowWhen?: (fieldName: string, value: any, formValue: any) => boolean;
  //Not yet implement
  footNote?: string;
  //#endregion
};

//TODO: TEST TYPE
export type ITextAreaField<T> = Partial<T> & {
  type: EnumFieldType.TEXTAREA;
  allowClear?: boolean;
  autoSize?: {
    minRows: number;
    maxRows: number;
  };
  maxLength?: number;
  showCount?: boolean;
};

export type ISelectField<T> = Partial<T> & {
  type: EnumFieldType.SELECT;
  options: Option[];
  tagRender?: (props) => React.ReactNode;
  showSearch?: boolean;
  showArrow?: boolean;
  removeIcon?: React.ReactNode;
  placeholder?: React.ReactNode;
  placement?: "bottomLeft" | "bottomRight" | "topLeft" | "topRight";
  mode?: "multiple" | "tags";
  clearIcon?: React.ReactNode;
  allowClear?: boolean;
  bordered?: boolean;
};

export type INumberField<T> = Partial<T> & {
  type: EnumFieldType.NUMBER;
  step?: number;
  max?: number;
  min?: number;
};

export type ICheckbox<T> = Partial<T> & {
  type: EnumFieldType.CHECKBOX;
  options: Option[];
  direction: "vertical" | "horizontal";
};

export type IRadio<T> = Partial<T> & {
  type: EnumFieldType.RADIO;
  options: Option[];
  direction: "vertical" | "horizontal";
  optionType: "default" | "button";
  buttonStyle?: "outline" | "solid";
};

export type IUpload<T> = Partial<T> & {
  type: EnumFieldType.UPLOAD;
  showUploadList?: boolean | ShowUploadListInterface;
  maxCount?: number;
  multiple?: boolean;
  limit?: number;
  accept?: string;
  directory?: boolean;
  listType: UploadListType;
  uploadType?: UploadType;
  uploadButton: React.ReactNode;
};

export type IDerivedText<T> = Partial<T> & {
  type: EnumFieldType.DERIVED_TEXT;
  formatter: (field: IBaseField, value: any, formValue: any) => string;
};

export type IDivider<T> = Partial<T> & {
  type: EnumFieldType.DIVIDER;
  plain?: boolean;
  dashed?: boolean;
  dividerType?: "horizontal" | "vertical";
};

export type ICustomized<T> = Partial<T> & {
  type: EnumFieldType.CUSTOMIZED;
  dynamic: boolean; // If set dynamic is true using Form.List otherwise Form.Item.
  factoryType: string; // If Field is Customized should be add factory Type in Factory
};

export type IList<T> = Partial<T> & {
  type: EnumFieldType.LIST;
  dynamic?: boolean; // If set dynamic is true using Form.List otherwise Form.Item.
  factoryType?: string; // If Field is Customized should be add factory Type in Factory
  renderItem: (
    record: any,
    index: number,
    operation: FormListOperation,
    field: T,
    form: FormInstance
  ) => React.ReactNode;
  showRemove?: boolean;
  showEdit?: boolean;
  renderAddButton?: (operation: FormListOperation) => React.ReactNode;
};

export type IField =
  | IBaseField
  | INumberField<IBaseField>
  | ITextAreaField<IBaseField>
  | ISelectField<IBaseField>
  | IDerivedText<IBaseField>
  | IDivider<IBaseField>
  | ICheckbox<IBaseField>
  | IRadio<IBaseField>
  | IUpload<IBaseField>
  | ICustomized<IBaseField>
  | IList<IBaseField>;

export type ISection<T> = Partial<T> & {
  type: EnumFieldType.SECTION;
  title?: React.ReactNode;
  fields: T[];
};

export type ISectionField = ISection<IField>;

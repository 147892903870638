import {Typography} from "antd";
import styled from "@emotion/styled";

export const StyledTitle = styled(Typography)`
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0.01em;
  text-align: center;
`;

/* eslint-disable react-hooks/exhaustive-deps */
import {User} from "auth-provider";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {bootstrap} from "store/auth.slice";

import {useHttp} from "utils/http";

export const useCheckFirstLogin = (userId, uuid) => {
  const client = useHttp();
  const dispatch: (...args: unknown[]) => Promise<User> = useDispatch();
  // useAsync<{ list: any[]; total: number }>();

  const markedFirstLogin = useCallback(async () => {
    const response = await client(`users/userguide/${uuid}`, {
      method: "POST",
    });
    dispatch(bootstrap());
    return response;
  }, []);

  const getEmails = () => {
    const userLoggedIn = localStorage.getItem("icw_logged_in");
    return userLoggedIn ? JSON.parse(userLoggedIn) : [];
  };

  const addLoggedInEmail = () => {
    const userLoggedIn = getEmails();
    const existed = userLoggedIn.find((id) => userId === id);
    !existed && userLoggedIn.push(userId);
    localStorage.setItem("icw_logged_in", JSON.stringify(userLoggedIn));
    return userLoggedIn;
  };

  const isFirstLogin = React.useMemo(() => {
    const users = getEmails();
    return !users.find((id) => userId === id);
  }, [userId]);

  return {
    isFirstLogin,
    addLoggedInEmail,
    markedFirstLogin,
  };
};

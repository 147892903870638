import {useAuth} from "context/auth-context";
import React from "react";
import {RbacContext} from "./context";
import {RULES} from "configs/rules";
import {find} from "lodash";

const RBACMiddleware = ({ children }) => {
  const [permissions, setPermissions] = React.useState([]);
  const { user } = useAuth();

  const canActivate = (resource: string, action: string, field: string) => {
    if (!permissions.length) return false;
    const allowedResource = find(permissions, { resource });

    if (!allowedResource) return false;
    if (field) {
      return (
        allowedResource.fields.includes(field) &&
        allowedResource.action.includes(action)
      );
    }
    return !!allowedResource.action.includes(action);
  };

  React.useEffect(() => {
    if (user) {
      setPermissions(RULES[user.userRole]);
    }
  }, [user]);

  return (
    <RbacContext.Provider value={canActivate}>{children}</RbacContext.Provider>
  );
};

export default RBACMiddleware;

import React, {SyntheticEvent} from "react";
import {Form, FormListOperation, List as AntdList, Space} from "antd";
import {IBaseField, IList} from "../FormRenderer/type";
// import { DeleteOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
// import FormRender from "../FormRenderer";

type IListProps = {
  field: IList<IBaseField>;
  value: any[];
  operation?: FormListOperation;
  onChange: (e: SyntheticEvent) => void;
  meta?: {
    errors: React.ReactNode[];
    warnings: React.ReactNode[];
  };
};

const Container = styled(Space)`
  width: 100%;
  margin-bottom: 24px;
  > .ant-space-item {
    width: 100%;
  }
`;

const List: React.FC<IListProps> = ({ value, field, operation }) => {
  const form = Form.useFormInstance();
  return (
    <Container direction="vertical">
      {field.label}
      {!!(value || []).length && (
        <AntdList
          bordered={false}
          split={false}
          dataSource={value || []}
          renderItem={(item, index) => {
            return field.renderItem(
              item,
              index,
              operation,
              field as IBaseField,
              form
            );
            //TODO: Will Implement Later
            // return (
            //   <AntdList.Item
            //     extra={
            //       <Space>
            //         {/* <Button type="link" icon={<EditFilled />}></Button> */}
            //         <Button
            //           type="text"
            //           danger
            //           onClick={() => {
            //             operation.remove(index);
            //           }}
            //           icon={<DeleteOutlined />}
            //         ></Button>
            //       </Space>
            //     }
            //   >
            //     <FormRender fields={[]}></FormRender>
            //     <Form.Item name={[field.name, item.field]}>
            //       {field.renderItem(
            //         item,
            //         index,
            //         operation,
            //         field as IBaseField,
            //         form
            //       )}
            //     </Form.Item>
            //   </AntdList.Item>
            // );
          }}
        ></AntdList>
      )}

      {field.renderAddButton ? field.renderAddButton(operation) : undefined}
    </Container>
  );
};

export default List;

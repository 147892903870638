import React, {SyntheticEvent} from "react";
import {Form, Select as AntdSelect} from "antd";
import {IBaseField, ISelectField} from "../FormRenderer/type";
import {isNil, pick} from "lodash";

type ISelectProps = {
  field: ISelectField<IBaseField>;
  value?: any;
  onChange: (event: SyntheticEvent) => void;
};

const Select: React.FC<ISelectProps> = ({ field, value, onChange }) => {
  const form = Form.useFormInstance();

  const disabled = React.useMemo(() => {
    if (field.shouldDisabled)
      return field.shouldDisabled(
        field as IBaseField,
        value,
        form.getFieldsValue()
      );
    return false;
  }, [field, value]);

  const options = React.useMemo(() => {
    return field.options.reduce((acc, option) => {
      if (
        option.showWhen &&
        option.showWhen(
          field as IBaseField,
          option,
          value,
          form.getFieldsValue()
        )
      ) {
        acc.push(option);
      }
      if (isNil(option.showWhen)) {
        acc.push(option);
      }
      return acc;
    }, []);
  }, [field, value]);
  const selectProps = React.useMemo(() => {
    return pick(field, [
      "tagRender",
      "showSearch",
      "showArrow",
      "removeIcon",
      "placeholder",
      "placement",
      "mode",
      "clearIcon",
      "allowClear",
      "bordered",
    ]) as any;
  }, [field, value]);
  return (
    <AntdSelect
      {...selectProps}
      disabled={disabled}
      options={options}
      onChange={(value, optionValue) => {
        onChange({
          target: {
            name: field.name,
            value: optionValue,
          },
        } as any);
      }}
      value={value}
    ></AntdSelect>
  );
};

export default Select;

import React from "react";
import {EnumFieldType} from "./type";

import DerivedText from "../Inputs/DerivedText";
import Section from "../Inputs/Section";
import Divider from "../Inputs/Divider";
import Checkbox from "../Inputs/Checkbox";
import Radio from "../Inputs/Radio";
import Uploader from "../Inputs/Uploader";
import Select from "../Inputs/Select";
import {FormInstance} from "antd";
import List from "../Inputs/List";
import {TextArea} from "../Inputs/TextArea";

type IFormConfigProvider = {
  form: FormInstance;
  children: React.ReactNode;
  factory?: Record<string, React.ElementType>;
};

const FACTORY_ELEMENT = {
  [EnumFieldType.TEXTAREA]: TextArea,
  [EnumFieldType.DERIVED_TEXT]: DerivedText,
  [EnumFieldType.DIVIDER]: Divider,
  [EnumFieldType.SECTION]: Section,
  [EnumFieldType.CHECKBOX]: Checkbox,
  [EnumFieldType.RADIO]: Radio,
  [EnumFieldType.UPLOAD]: Uploader,
  [EnumFieldType.LIST]: List,
  [EnumFieldType.SELECT]: Select,
};

const FormConfigContext = React.createContext({
  FACTORY: FACTORY_ELEMENT,
});

const FormConfigProvider = ({
  children,
  form,
  factory,
}: IFormConfigProvider) => {
  const FACTORY = React.useMemo(() => {
    return { ...FACTORY_ELEMENT, ...factory };
  }, [factory]);
  return (
    <FormConfigContext.Provider
      value={{
        FACTORY,
      }}
    >
      {children}
    </FormConfigContext.Provider>
  );
};

export const useFormConfigContext = () => {
  const context = React.useContext(FormConfigContext);
  return context;
};

export default FormConfigProvider;

import {Input} from "antd";
import React from "react";
import DerivedText from "./DerivedText";

export const TextArea = (props) => {
  const isReadonly = React.useMemo(() => {
    const { readonly } = props.field;
    if (readonly === null || readonly === undefined) return false;
    return readonly;
  }, [props.field.readonly]);

  return isReadonly ? (
    <DerivedText {...props}></DerivedText>
  ) : (
    <Input.TextArea {...props}></Input.TextArea>
  );
};

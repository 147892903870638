import React, {ReactNode} from "react";
import {AuthProvider} from "context/auth-context";
import {QueryClient, QueryClientProvider} from "react-query";
import {Provider} from "react-redux";
import {store} from "store";
import {StaticDataProvider} from "./static-data-context";

export const AppProviders = ({ children }: { children: ReactNode }) => {
  const queryClient = new QueryClient();

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <StaticDataProvider>{children}</StaticDataProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Provider>
  );
};

import React, {SyntheticEvent} from "react";
import {Form, Radio as AntdRadio, RadioProps} from "antd";

import {isNil} from "lodash";
import styled from "@emotion/styled";
import {IBaseField, IRadio} from "../FormRenderer/type";

type IRadioProps = {
  field: IRadio<IBaseField>;
  value: any;
  onChange: (event: SyntheticEvent) => void;
};

const StyledRadioGroup = styled(AntdRadio.Group, {
  shouldForwardProp: (prop) => !["direction"].includes(prop),
})<
  Partial<
    RadioProps & {
      direction?: "horizontal" | "vertical";
    }
  >
>`
  display: flex;
  flex-direction: ${(prop) =>
    prop.direction === "vertical" ? "column" : "row"};
  gap: 16px;
`;

const Radio: React.FC<IRadioProps> = ({ field, value, onChange }) => {
  const form = Form.useFormInstance();

  const disabled = React.useMemo(() => {
    if (field.shouldDisabled)
      return field.shouldDisabled(
        field as IBaseField,
        value,
        form.getFieldsValue()
      );
    return false;
  }, [field, value]);

  const options = React.useMemo(() => {
    return field.options.reduce((acc, option) => {
      if (
        option.showWhen &&
        option.showWhen(
          field as IBaseField,
          option,
          value,
          form.getFieldsValue()
        )
      ) {
        acc.push(option);
      }
      if (isNil(option.showWhen)) {
        acc.push(option);
      }
      return acc;
    }, []);
  }, [field, value]);

  return (
    <StyledRadioGroup
      disabled={disabled}
      direction={field.direction}
      optionType={field.optionType}
      buttonStyle={field.buttonStyle}
      options={options}
      value={value}
      onChange={(e) => {
        onChange(e as any);
      }}
    ></StyledRadioGroup>
  );
};

export default Radio;

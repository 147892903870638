import React, {useCallback} from "react";
import CusPageHeader from "components/Molecules/header";
import Page from "components/Molecules/page";
import {useNavigate, useParams} from "react-router-dom";
import {ReviewTable} from "../../components/ReviewTable";
// import { ReviewMockData } from "fixtures/mockData";
import {STATUS} from "../../constants";
import {withFiltersOptions} from "HOCs/withFiltersOptions";
import {StatusType, useListReview} from "pages/Reviews/api";
import omit from "lodash/omit";

const ReviewAll = ({
  filters,
}: {
  filters: Record<
    string,
    Array<{
      id: string;
      value: string;
      text: string;
    }>
  >;
}) => {
  const navigate = useNavigate();
  const { status } = useParams();
  const generateFilterBaseStatus = () => {
    if (status === "waiting-for-review")
      return ["waiting_for_review", "rejected_quote_accepted"];
    if (status === "completed")
      return [
        "completed",
        "pending_e_signature",
        "conformity_certificate_expired",
      ];
    if (status === "rejected")
      return [
        // "rejected",
        "rejected_request_additional_test",
        "rejected_quote_accepted",
        "rejected_request_revise",
        "rejected_others",
      ];
    if (status === "all") {
      return filters.status.map(({ value }) => value);
    }
    return [status];
  };
  const [allTask, onChangeAllTask] = useListReview(
    generateFilterBaseStatus() as StatusType[]
  );
  const onBack = useCallback(() => {
    navigate("-1");
  }, [navigate]);

  React.useEffect(() => {
    if (!status) {
      // onBack();
    }
  }, [onBack, status]);

  const onChange = (
    pagination: { current: number; pageSize: number },
    filtered: Record<string, any>,
    sorted: Record<string, any>,
    extra: { action: string; currentDataSource: Array<any> }
  ) => {
    onChangeAllTask(
      pagination,
      { ...filtered, status: generateFilterBaseStatus() },
      sorted,
      extra
    );
  };

  return (
    <>
      <CusPageHeader
        title={STATUS[status as string]}
        onBack={onBack}
      ></CusPageHeader>
      <Page>
        <ReviewTable
          defaultFilteredValue={{ status: generateFilterBaseStatus() }}
          onChange={onChange}
          loading={allTask.isFetching}
          dataSource={allTask?.data?.list ?? []}
          filters={status !== "all" ? omit(filters, "status") : filters}
          pagination={{
            total: allTask?.data?.total,
          }}
        ></ReviewTable>
      </Page>
    </>
  );
};

const wrappedWithFilterOptions = withFiltersOptions(ReviewAll);

export default wrappedWithFilterOptions;

import {useHttp} from "utils/http";
import {useAsync} from "utils/use-async";
import {useCallback, useEffect} from "react";
import {Product} from "constants/interface/product/product";
import {Task} from "constants/interface/task";
import {cleanObject} from "utils";

export const useProductDetail = (param?: { id?: string }) => {
  const client = useHttp();
  const { run, ...result } = useAsync<Product>();

  const fetchProductDetails = useCallback(
    () => client(`products/${param?.id}`, {}),
    [param, client]
  );

  useEffect(() => {
    run(fetchProductDetails(), {
      retry: fetchProductDetails,
    });
  }, [param, run, fetchProductDetails]);

  return result;
};

export const useProductTasks = (param?: { id?: string }) => {
  const client = useHttp();
  const { run, ...result } = useAsync<Task[]>();

  const fetchProductTasks = useCallback(
    () => client(`products/${param?.id}/tasks`, {}),
    [param, client]
  );

  useEffect(() => {
    run(fetchProductTasks(), {
      retry: fetchProductTasks,
    });
  }, [param, run, fetchProductTasks]);

  return result;
};

export const useTaskTraction = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = ({
    params,
    taskId,
  }: {
    params?: Partial<any>;
    taskId: string;
  }) => {
    return run(
      client(`tasks/${taskId}/payments`, {
        data: cleanObject(params || {}),
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useTaskInsufficientInfo = (taskId: string) => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  // const params = { status: "insufficient_info" };
  const mutate = (params: Record<string, any>) => {
    return run(
      client(`tasks/${taskId}/test-plan`, {
        data: cleanObject(params || {}),
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

import {Space} from "antd";
import React from "react";
import {IBaseField, ISection} from "../FormRenderer/type";
import FormRender from "../FormRenderer";
import styled from "@emotion/styled";
import {isNil} from "lodash";
import {Action} from "configs/rules";

type ISectionProps = {
  field: ISection<IBaseField>;
  value?: any;
};

const Container = styled(Space)`
  width: 100%;
  > .ant-space-item {
    width: 100%;
  }
`;

const WrapperTitle = styled.span`
  color: var(--text-text-600, #232b3a);
  /* Header/H2 - Medium */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.2px;
`;

const Section: React.FC<ISectionProps> = ({
  field,
  resource,
  action,
  canActivate,
}: any) => {
  const fields = field.fields.map((subField) => {
    const { name } = subField;
    if (canActivate && name) {
      const isCreate = canActivate(
        resource,
        Action.CREATE,
        name.join ? name.join(".") : name
      );
      const isEdit = canActivate(
        resource,
        Action.EDIT,
        name.join ? name.join(".") : name
      );
      return {
        ...subField,
        canActivate,
        readonly: !(isCreate || isEdit) || subField.readonly,
      };
    }
    return subField;
  });

  return (
    <section id={field.sectionName as string}>
      <Container direction="vertical">
        {isNil(field.title) ? undefined : (
          <WrapperTitle>{field.title}</WrapperTitle>
        )}
        <FormRender fields={fields}></FormRender>
      </Container>
    </section>
  );
};

export default Section;

import {Outlet, useLocation, useNavigate} from "react-router-dom";
import React from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import {Button, Modal, Result} from "antd";
import NoBoardingImage from "assets/images/noboarding.png";

const Authorization = () => {
  const [shouldPrevent, setShouldPrevent] = React.useState(
    window.screen.width <= 800
  );
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    //Navigate to Sign In form.
    if (location.pathname === "/authorization") {
      navigate("/authorization/sign-in", { replace: true });
    }
  }, [location, navigate]);

  return (
    <div>
      <Outlet />
      <Modal
        closable={false}
        width="480"
        centered
        visible={shouldPrevent}
        footer={null}
      >
        <Result
          icon={
            <img width="100%" src={NoBoardingImage} alt="no-boarding"></img>
          }
          title="e-CPSR supports screen larger than 800px"
          subTitle="For your better experience, please use a higher screen resolution device"
          extra={
            <Button
              onClick={() => setShouldPrevent(false)}
              block
              type="primary"
            >
              Okay
            </Button>
          }
        ></Result>
      </Modal>
    </div>
  );
};

Authorization.SignIn = SignIn;
Authorization.SignUp = SignUp;
Authorization.ForgotPassword = ForgotPassword;
Authorization.ResetPassword = ResetPassword;
export default Authorization;

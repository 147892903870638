import React from "react";
import {Layout as AntdLayout} from "antd";
import styled from "@emotion/styled";
import cx from "classnames";

const StyledLayout = styled(AntdLayout)`
  min-height: 100vh;
  background: #fff;
`;

const StyledSider = styled(({ siderBg, ...props }: any) => {
  return <AntdLayout.Sider {...props}></AntdLayout.Sider>;
})`
  background: url(${(props: any) => props.siderBg}) no-repeat;
  background-size: cover;
  .ant-layout-sider-children {
    background: linear-gradient(
      63.67deg,
      rgba(135, 140, 215, 0.4) 16.44%,
      rgba(90, 195, 240, 0.4) 94.29%
    );
  }
`;
export const Layout = ({
  className,
  children,
  sider = {},
  revert = false,
  ...props
}: any) => {
  const { renderSider, ...siderProps } = sider || {};
  return (
    <StyledLayout className={cx(className)}>
      {revert && sider && (
        <StyledSider {...(siderProps as any)} width={"30vw"}>
          {renderSider && renderSider()}
        </StyledSider>
      )}
      <AntdLayout.Content>{children}</AntdLayout.Content>
      {!revert && sider && (
        <StyledSider {...(siderProps as any)} width={"30vw"}>
          {renderSider && renderSider()}
        </StyledSider>
      )}
    </StyledLayout>
  );
};

import React from "react";
import {Form, FormItemProps, Input} from "antd";
import {CheckOutlined, EyeInvisibleOutlined, EyeTwoTone,} from "@ant-design/icons";
import {PasswordProps} from "antd/lib/input/Password";
import styled from "@emotion/styled";
import {usePasswordValidator} from "hooks";

const InputPassword = (props: Partial<PasswordProps>) => {
  return (
    <Input.Password
      iconRender={(visible) =>
        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
      }
      {...props}
    ></Input.Password>
  );
};

const ListValidation = styled.ul`
  padding-left: 0;
  list-style: none;
  margin-top: 16px;
  li {
    color: #aaaaaa;
    span {
      margin-right: 8px;
    }
    &.valid {
      color: #000000;
      span {
        color: #b0d400;
      }
    }
  }
`;
const WithFormItem = (props: {
  inputProps: Partial<PasswordProps>;
  formItemProps?: FormItemProps;
  validationOptions: Array<{ validation: string; label: string }>;
  renderValidationOption?: (option: any, validators: any[]) => React.ReactNode;
  validate?: (
    value: string,
    validatorOptions: any[]
  ) => {
    isValid: boolean;
    validators: any[];
  };
}) => {
  const [passwordValidations, setPasswordValidations] = React.useState<any[]>(
    []
  );
  //Default Options
  const [, validate] = usePasswordValidator({
    min: 8,
    spaces: false,
    letters: true,
    digits: true,
    lowercase: true,
    uppercase: true,
    symbols: true,
  });
  const checkValid = (value: string) => {
    if (props.validate) {
      const { isValid, validators } = props.validate(
        value,
        props.validationOptions
      );
      setPasswordValidations(validators);
      return isValid;
    }
    const validators: any[] = validate(value || "");

    const otherValidators = validators.filter(
      ({ validation }: any) =>
        validation !== "digits" && validation !== "symbols"
    );
    const numberOrSymbols = validators.filter(
      ({ validation }: any) =>
        validation === "digits" || validation === "symbols"
    );
    const isValid =
      otherValidators.length || numberOrSymbols.length > 1 ? false : true;
    setPasswordValidations(isValid ? [] : validators);
    return isValid;
  };

  return (
    <Form.Item
      {...props.formItemProps}
      help={
        !!passwordValidations.length && (
          <ListValidation>
            {props.validationOptions.map(({ validation, label }) => {
              if (props.renderValidationOption) {
                return props.renderValidationOption(
                  validation,
                  passwordValidations
                );
              }
              //All Default, if you want just passing customize function.
              let isInvalid = passwordValidations.find(
                ({ validation: key }) => {
                  return key === validation;
                }
              );
              if (validation === "symbols")
                isInvalid =
                  passwordValidations.filter(
                    ({ validation: key }) =>
                      key === validation || key === "digits"
                  ).length > 1;

              return (
                <li className={isInvalid ? "" : "valid"} key={validation}>
                  <CheckOutlined />
                  {label}
                </li>
              );
            })}
          </ListValidation>
        )
      }
      name="password"
      rules={[
        {
          validator: (_, value) => {
            const isValid = checkValid(value);
            if (!isValid) {
              return Promise.reject(new Error(""));
            }

            return Promise.resolve();
          },
        },
      ]}
    >
      <Input.Password
        {...props.inputProps}
        size="large"
        type="password"
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      ></Input.Password>
    </Form.Item>
  );
};
InputPassword.WithFormItem = WithFormItem;

export default InputPassword;

import React from "react";
import {ColumnsType} from "rc-table/lib/interface";
import moment from "moment";

const DOCColDefs: ColumnsType = [
  {
    title: "Test Item",
    dataIndex: "testItem",
    key: "testItem",
  },
  {
    title: "Citation",
    dataIndex: "citation",
    key: "citation",
  },
  {
    title: "Legislation",
    dataIndex: "legislation",
    key: "legislation",
  },
  {
    title: "Harmonized Standard",
    dataIndex: "harmonizedStandard",
    key: "harmonizedStandard",
    render: (_, record: any) => {
      return (
        <span>
          {record?.citation} <br></br>
          {record?.testItem}
        </span>
      );
    },
  },
  {
    title: "Lab",
    dataIndex: "lab",
    key: "lab",
  },
  {
    title: "Report Number",
    dataIndex: "reportNumber",
    key: "reportNumber",
  },
  {
    title: "Report Issue Date",
    dataIndex: "reportIssueDate",
    key: "reportIssueDate",
    render: (value: any) => {
      return moment(value).format("DD/MM/YYYY");
    },
  },
];

const CPCColDefs = [
  {
    title: "Test Item",
    dataIndex: "testItem",
    key: "testItem",
  },

  {
    title: "Citation",
    dataIndex: "citation",
    key: "citation",
  },
  {
    title: "Lab",
    dataIndex: "lab",
    key: "lab",
  },
  {
    title: "Report Number",
    dataIndex: "reportNumber",
    key: "reportNumber",
  },
  {
    title: "Report Issue Date",
    dataIndex: "reportIssueDate",
    key: "reportIssueDate",
    render: (value: any) => {
      return moment(value).format("DD/MM/YYYY");
    },
  },
];

const ReportColDefs = [
  ...CPCColDefs,
  {
    title: "Test Result",
    dataIndex: "testResult",
    key: "testResult",
    render: (value: any) => {
      return value;
    },
  },
];

const CONFORMITY_COLS_DEF = {
  CPC: CPCColDefs,
  DOC: DOCColDefs,
  "report-data": ReportColDefs,
};

export const getConformityColDefs = (conformityType: string) => {
  return CONFORMITY_COLS_DEF[conformityType];
};

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lowerCaseLng: true,
    supportedLngs: ["en", "zh-cn", "zh-CN"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    resources: {
      "en-US": {
        common: require("../locales/en/common.json"),
        login: require("../locales/en/login.json"),
        register: require("../locales/en/register.json"),
        result: require("../locales/en/result.json"),
      },
      en: {
        common: require("../locales/en/common.json"),
        login: require("../locales/en/login.json"),
        register: require("../locales/en/register.json"),
        result: require("../locales/en/result.json"),
      },
      "zh-cn": {
        common: require("../locales/zh-cn/common.json"),
        login: require("../locales/zh-cn/login.json"),
        register: require("../locales/zh-cn/register.json"),
        result: require("../locales/zh-cn/result.json"),
      },
    },
  });

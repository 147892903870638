import React from "react";
import {Badge, Space} from "antd";
import {Status} from "../../../constants/interface/product/status";
import styled from "@emotion/styled";

const StyledBadge = styled(Badge)`
  .ant-badge-status-text {
    color: #777777;
  }
`;
const StyledTestPlanStatus = styled(Badge)`
  .ant-badge-status-dot {
    display: ${(props: any) => (props.dot ? "inline-block" : "none")};
  }
  .ant-badge-status-text {
    color: ${(props: any) => (props.dot ? "#777777" : props.color || "")};
  }
`;
export const productStatus = (status?: string, rejectedStatus?: string) => {
  const tempStatus = rejectedStatus || status || "pending_payment";

  const statusName: Status = {
    passed: { color: "green", label: "Passed" },
    completed: {
      color: "green",
      label: "Approved",
    },
    waiting_for_review: {
      color: "yellow",
      label: "Waiting for Review",
    },
    pending_payment: {
      color: "yellow",
      label: "Pending Payment",
    },
    pending_e_signature: {
      color: "yellow",
      label: "Pending E-sign",
    },
    rejected: {
      color: "red",
      label: <Space direction="vertical">Rejected</Space>,
    },
    rejected_request_additional_test: {
      color: "red",
      label: (
        <Space direction="vertical">Rejected (Request Additional Test)</Space>
      ),
    },
    rejected_quote_accepted: {
      color: "red",
      label: (
        <Space direction="vertical">Rejected (Request Quote Accepted)</Space>
      ),
    },
    rejected_request_revise: {
      color: "red",
      label: <Space direction="vertical">Rejected (Request revise)</Space>,
    },
    rejected_others: {
      color: "red",
      label: <Space direction="vertical">Rejected</Space>,
    },
    missing_documents: {
      color: "#E8E8E8",
      label: "Missing Documents",
    },
    pending_completed_document_validation: {
      color: "#E8E8E8",
      label: "Pending completed document validation",
    },
    conformity_certificate_expired: {
      color: "red",
      label: "Conformity Certificate Expired",
    },
    conformity_certificate_not_required: {
      color: "#E8E8E8",
      label: "Conformity Certificate Not Required",
    },
    conformity_certificate_required: {
      color: "#E8E8E8",
      label: "Conformity Certificate Required",
    },
    merchant_uploaded: {
      color: "#E8E8E8",
      label: "Merchant Uploaded",
    },
  };

  return (
    <StyledBadge
      status={tempStatus !== "completed" ? "processing" : "default"}
      dot
      size={"small"}
      color={statusName[tempStatus].color}
      text={statusName[tempStatus].label}
      // title={statusName[tempStatus].label}
    />
  );
};
export const testPlanStatus = (status?: string, showDot?: boolean) => {
  const tempStatus = status || "pending";

  const statusName: Record<string, any> = {
    manual_assigned: { color: "green", label: "Manual assigned" },
    auto_assigned: { color: "green", label: "Auto assigned" },
    insufficient_info: {
      color: "red",
      label: "Insufficient Info",
    },
    pending: { color: "#3A4861", label: "Pending" },
    missing_test_plan: { color: "#E8E8E8", label: "Pending" },
  };

  return (
    <StyledTestPlanStatus
      dot={showDot}
      status={tempStatus !== "completed" ? "processing" : "default"}
      size={"small"}
      color={statusName[tempStatus].color}
      text={statusName[tempStatus].label}
      title={statusName[tempStatus].label}
    />
  );
};

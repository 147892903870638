import styled from "@emotion/styled";
import {List, Tabs} from "antd";
import React from "react";
import RejectReasonItem from "./RejectReasonItem";
import {Task} from "constants/interface/task";
import {ICompliance} from "constants/interface/compliance";
import {get} from "lodash";
import {REJECT_REASON} from "pages/Reviews/views/ComplianceCheck/constants";
import {USER_ROLES} from "pages/Reviews/constants";

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--neutrals-grey-100, #ccc) !important;
  background: #fff;
  > h5 {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  > div {
    .ant-tabs-tab {
      padding: 10px 12px;
      color: var(--neutrals-grey-100, #ccc);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      line-height: 16px;
      letter-spacing: 0.42px;
    }
  }
`;

const StyledListItem = styled(List.Item)`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--neutrals-grey-100, #ccc) !important;
  margin-bottom: 16px;
`;

const RejectReasonSection = ({
  // task,
  compliance,
  acceptQuotation,
  reviseQuotation,
  isRevising,
  isAccepting,
  role,
}: {
  role?: USER_ROLES;
  task?: Task;
  compliance?: ICompliance;
  acceptQuotation?: (additionalQuotationTestId: string) => void;
  isAccepting?: boolean;
  reviseQuotation?: (additionalQuotationTestId: string, report) => void;
  isRevising?: boolean;
}) => {
  return (
    <Container
      hidden={
        !Array.isArray(compliance?.rejectReasons) ||
        !compliance?.rejectReasons.length
      }
    >
      <h5>Reject Reason</h5>
      <StyledTabs>
        <Tabs.TabPane tab="Reason" key="item-1">
          <List
            dataSource={get(compliance, "rejectReasons", []) || []}
            renderItem={(reject) => {
              const {
                reason,
                remark,
                files,
                testItems,
                rejectReason,
                createdAt,
                additionalTestQuotation,
              } = reject;
              const reasonOption = REJECT_REASON.find(
                ({ value }) => value === reason
              );
              return (
                <StyledListItem>
                  <RejectReasonItem
                    hideQuotation={role === USER_ROLES.REVIEWER}
                    onAcceptQuotation={() => {
                      acceptQuotation(additionalTestQuotation?.id);
                    }}
                    onReviseQuotation={(report) => {
                      reviseQuotation(additionalTestQuotation?.id, report);
                    }}
                    isRevising={isRevising}
                    isAccepting={isAccepting}
                    requestTestItems={testItems}
                    additionalDocuments={files || []}
                    remark={remark}
                    additionalTestQuotation={additionalTestQuotation}
                    rejectReason={rejectReason}
                    status={additionalTestQuotation?.status}
                    createdAt={createdAt}
                  ></RejectReasonItem>
                </StyledListItem>
              );
            }}
          ></List>
        </Tabs.TabPane>
      </StyledTabs>
    </Container>
  );
};

export default RejectReasonSection;

import React from "react";
import {BrowserRouter as Router, Navigate} from "react-router-dom";
import {Route, Routes} from "react-router";

export const withResolveRoutes = (role: string, routes: any[]) => {
  const WithRouter = () => {
    const resolvedRoutes = React.useMemo(() => {
      return routes.reduce((acc: typeof routes[], route: any) => {
        const { canAccess } = route;
        if (!canAccess || canAccess(role)) {
          acc.push(route);
        }
        return acc;
      }, []);
    }, []);

    const renderRoute = React.useCallback((routes: any) => {
      return routes.map(
        ({ children = [], index, key, element: Component, path }: any) => {
          return (
            <Route
              path={path}
              index={index}
              key={key}
              element={Component && <Component />}
            >
              {!!children.length &&
                children.map((nestedRoute: any) => {
                  return renderRoute([nestedRoute]);
                })}
            </Route>
          );
        }
      );
    }, []);

    return (
      <Router>
        <Routes>
          {renderRoute(resolvedRoutes)}
          <Route
            path="*"
            element={<Navigate to="/" replace></Navigate>}
          ></Route>
        </Routes>
      </Router>
    );
  };

  WithRouter.displayName = `withResolveRoutes(${Router})`;
  return <WithRouter />;
};

import React from "react";
import {useAuth} from "context/auth-context";
import {ErrorBoundary} from "components/error-boundary";
import {FullPageErrorFallback} from "components/lib";
import "./utils/i18n";
import "./global.less";
import {Navigate} from "react-router-dom";
import {withResolveRoutes} from "HOCs/withResolveRouter";
import {AuthorizationRoutes, Routes as AuthenticatedRoutes,} from "pages/routes";
import {Worker} from "@react-pdf-viewer/core";
import RBACMiddleware from "components/RBAC";

function App() {
  const { user } = useAuth();

  React.useEffect(() => {
    //This effect to prevent using app by mobile
  }, []);

  return (
    <Worker workerUrl="https://unpkg.com/@react-pdf-viewer/pdfjs-dist-signature@2.5.207/build/pdf.worker.js">
      <div className="App">
        <ErrorBoundary fallbackRender={FullPageErrorFallback}>
          <RBACMiddleware>
            {withResolveRoutes(user ? user?.userRole : "", [
              ...(!user
                ? [
                    {
                      key: "index",
                      path: "/",
                      element: () => (
                        <Navigate to="/authorization/sign-in"></Navigate>
                      ),
                    },
                  ]
                : []),
              ...AuthorizationRoutes,
              ...AuthenticatedRoutes,
            ])}
          </RBACMiddleware>
        </ErrorBoundary>
      </div>
    </Worker>
  );
}

export default App;

import {END_POINTS} from "configs/endpoints";
import React from "react";
import {useHttp} from "utils/http";

export const useAcceptQuotation = (taskId) => {
  const client = useHttp();

  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const acceptQuotation = async (additionalTestQuotationId: string) => {
    setLoading(true);
    const response = await client(
      `${END_POINTS.tasks}/${taskId}/compliance/request-additional-test/accept-quotation/${additionalTestQuotationId}`,
      {
        method: "POST",
      }
    );

    setData(response);
    setLoading(false);
    return response;
  };
  return {
    data,
    acceptQuotation,
    loading,
  };
};

import {first} from "lodash";
import set from "lodash/setWith";

/**
 * Using parse data base on the list mapping.
 * if value is undefined not need to add into output
 * @param {*} data
 * @param {*} mapping
 * @param {*} extra
 * @returns
 */
const parser = async (data, mapping, extra) => {
  if (!data) return {};
  if (!mapping || !mapping.length) {
    throw new Error("Please provide parser mapping");
  }
  const output = mapping.reduce(async (acc, current) => {
    const currentData = await acc;
    const [key, parseFrom, parser] = current;
    if (key && parser) {
      if (Array.isArray(parser)) {
        const [parseFn, ...params] = parser;
        const value = await parseFn(parseFrom, ...params)(data, extra);
        if (value !== undefined) set(currentData, key, value);
        return currentData;
      }
      const name = Array.isArray(parseFrom) ? first(parseFrom) : parseFrom;
      const value = await parser(name)(data, extra);
      if (value !== undefined) set(currentData, key, value);
    }
    return currentData;
  }, Promise.resolve({}));
  return output;
};
/**
 * Using reverse data base on the list mapping.
 * if value is undefined not need to add into output
 * @param {*} data
 * @param {*} mapping
 * @param {*} extra
 * @returns
 */
const reverse = async (data, mapping, extra) => {
  if (!data) return {};
  if (!mapping || !mapping.length) {
    throw new Error("Please provide reverse mapping");
  }
  const output = await mapping.reduce(async (acc, current) => {
    const currentData = await acc;
    const [parseFrom, reverseTo, , reverse] = current;
    if (reverse && reverseTo) {
      if (Array.isArray(reverse)) {
        const [reverseFn, ...params] = reverse;
        const value = reverseFn(parseFrom, ...params)(data, extra);
        if (value !== undefined) {
          if (Array.isArray(reverseTo)) {
            reverseTo.forEach((name) => {
              set(currentData, name, value);
            });
            return currentData;
          }
          set(currentData, reverseTo, value);
        }
        return currentData;
      }
      const value = reverse(parseFrom)(data, extra);
      if (value !== undefined) {
        if (Array.isArray(reverseTo)) {
          reverseTo.forEach((name) => {
            set(currentData, name, value);
          });
          return currentData;
        }
        set(currentData, reverseTo, value);
      }
    }
    return currentData;
  }, Promise.resolve({}));

  return output;
};

export { parser, reverse };

import React from "react";
import {IBaseField, IField} from "../FormRenderer/type";
import {Form} from "antd";

type IDerivedText = {
  field: IField;
  value?: any;
};

const DerivedText: React.FC<IDerivedText> = ({ field, value }) => {
  const form = Form.useFormInstance();
  const formattedValue = React.useMemo(() => {
    const { formatter } = field;
    if (!formatter) return value || "N/A";
    return formatter(field as IBaseField, value, form.getFieldsValue()) as any;
  }, [value, field]);

  return (
    <span style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
      {formattedValue}
    </span>
  );
};

export default DerivedText;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {Layout} from "../components/Layout";
import {StyledTitle} from "../components/Title";
import styled from "@emotion/styled";
import LoginWithQMAS from "components/Molecules/login-with-qmas";
import {Button, Divider, message, Modal, notification, Space, Typography,} from "antd";
import SiderBG from "assets/backgrounds/authorization-sider-bg.png";
import ECSPR from "assets/logo/ecpsr-logo-colored.png";
import ICWPoweredBy from "assets/logo/icw-powered-by-logo.svg";
import {useLocation, useNavigate} from "react-router-dom";
import SignInForm from "components/Organisms/forms/sign-in";
import QMASSignInForm from "components/Organisms/forms/sign-in-qmas";

import "./SignIn.less";
import {useAuth} from "context/auth-context";
import {useAsync} from "utils/use-async";
import {withTranslation} from "react-i18next";
import {User} from "auth-provider";
import {useDispatch} from "react-redux";
import {bootstrap} from "store/auth.slice";
import {SIGN_IN_CONFIGS} from "configs/features";
import {css} from "@emotion/react";
import Footer from "../components/Footer";

const Container = styled.div<any>`
  display: flex;
  justify-content: center;
  algin-items: center;
  flex-direction: column;
  form {
    align-self: center;
    min-width: 410px;
    max-width: 400px;
  }
  height: 100vh;
  ${(prop: any) =>
    prop.bgImage
      ? css`
          background: url(${prop.bgImage});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `
      : ""}
`;
const StyledDivider = styled(Divider)`
  &.ant-divider-horizontal.ant-divider-with-text {
    margin-top: 30px;
    margin-bottom: 16px;
    border-top-color: transparent;
  }

  > span {
    color: #aaaaaa;
    font-size: 14px;
    font-weight: 400;
  }
`;
const Sider = styled.div`
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    h1 {
      color: #ebedef;
      font-size: 40px;
      font-weight: 700;
      line-height: 47px;
      letter-spacing: 0.01em;
      text-align: center;
    }
    div.ant-typography {
      max-width: 315px;
      color: #ebedef;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: center;
    }
    button {
      margin-top: 60px;
      width: 140px;
    }
  }
`;
const StyledFooter = styled(Space)`
  justify-content: flex-end;
`;
const StyledContainerImage = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 95px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Config = SIGN_IN_CONFIGS.walmart;

const SignIn = ({ t }) => {
  const { login, user, verifyWithToken } = useAuth();
  const dispatch: (...args: unknown[]) => Promise<User> = useDispatch();

  const { run, isLoading } = useAsync(undefined, { throwOnError: true });
  const { search } = useLocation();
  const [showLoginQMAS, setShowLoginQMAS] = React.useState(false);
  const translate = (key: string) => {
    return t && t(key);
  };
  // HTMLFormElement extends Element
  const handleSubmit = async (values: { email: string; password: string }) => {
    try {
      await run(login(values));
    } catch (e) {
      message.error((e as any).message || "Invalid Account or Password");
      // onError(e as any);
    }
  };
  const navigate = useNavigate();
  const goToSignUp = React.useCallback(() => {
    navigate("/authorization/sign-up");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const goToForgotPassword = React.useCallback(() => {
    navigate("/authorization/forgot-password");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickLoginQMAS = () => {
    setShowLoginQMAS(true);
  };

  const renderSider = React.useCallback(() => {
    return (
      <Sider>
        <div className="content">
          <Space direction="vertical" align="center" size={[10, 10]}>
            <Typography.Title>New Here?</Typography.Title>
            <Typography.Paragraph>
              Register our e-CPSR platform for your product compliance review
              management!
            </Typography.Paragraph>
          </Space>
          <Button onClick={goToSignUp} ghost size="large">
            Sign Up
          </Button>
        </div>
        <StyledFooter align="end">
          <img src={ICWPoweredBy} alt="e-cspr"></img>
        </StyledFooter>
      </Sider>
    );
  }, [goToSignUp]);

  React.useEffect(() => {
    if (user) {
      //User Logged In.
      dispatch(
        bootstrap(() => {
          navigate("/");
        })
      );
    }
  }, [navigate, user]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.get("token")) {
      verifyWithToken({
        token: searchParams.get("token"),
      }).catch((error) => {
        notification.error({ message: error.message });
      });
    }
  }, [search, verifyWithToken]);

  return (
    <Layout
      className={"container"}
      sider={
        Config.layout.sider
          ? { siderBg: SiderBG, renderSider: renderSider }
          : null
      }
    >
      <Container bgImage={Config.backgroundImage}>
        {Config.layout.showECSPRLogo && (
          <StyledContainerImage>
            <img src={ECSPR} alt="e-cspr"></img>
          </StyledContainerImage>
        )}

        {Config.layout.showWelcome && (
          <StyledTitle className={"title"}>Welcome Back</StyledTitle>
        )}

        {Config.layout.showLoginWithQMAS && (
          <>
            <LoginWithQMAS
              onClick={onClickLoginQMAS}
              label={translate("login:Buttons.connectQMAS")}
            ></LoginWithQMAS>
            <StyledDivider>or use your email:</StyledDivider>
          </>
        )}

        <SignInForm
          sologan={Config.sologan}
          logo={Config.branchLogo}
          config={Config.form}
          handleSubmit={handleSubmit}
          onForgotPassword={goToForgotPassword}
          loading={isLoading}
        ></SignInForm>
        <Footer poweredByLogo={Config.layout.footer.logo}></Footer>
      </Container>
      <Modal
        footer={null}
        visible={showLoginQMAS}
        onCancel={() => setShowLoginQMAS(false)}
      >
        <QMASSignInForm
          handleSubmit={handleSubmit}
          loading={isLoading}
        ></QMASSignInForm>
      </Modal>
    </Layout>
  );
};

export default React.memo(
  withTranslation(["common", "login", "register"])(SignIn)
);

import React, {useEffect, useState} from "react";
import CusPageHeader from "../../../components/Molecules/header";
import {Avatar, Button, Col, Divider, Form, Input, message, Row, Spin, Typography, Upload, UploadFile,} from "antd";
import {DeleteOutlined, FileImageOutlined, UploadOutlined,} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import Page from "../../../components/Molecules/page";
import {ModalForm} from "../../../components/Organisms/forms/modal-form";
import SignatureCanvas from "react-signature-canvas";
import {useDebounce} from "../../../utils";
import {useAddESign, useComplianceConformityCertificate} from "./api";
import {useGetFileUrl} from "../api";
import {File} from "../../../components/Organisms/uploads/upload-doc";
import {useUploadFile} from "../../../components/Organisms/uploads/api";
import {useProductDetail} from "../ProjectPage/api";
import {useTaskCompliance} from "../UploadDocument/api";
import {useHttp} from "../../../utils/http";
import {useAuth} from "context/auth-context";
import {MainLayout} from "components/Organisms/layouts";
import {useGenerateCertPdf} from "pages/Reviews/api";
import {Viewer} from "@react-pdf-viewer/core";
import styled from "@emotion/styled";
import moment from "moment";
import DocIcon from "components/Atoms/images/file-icon/doc_icon.png";
import PdfIcon from "components/Atoms/images/file-icon/pdf_icon.png";
import XlsIcon from "components/Atoms/images/file-icon/xls_icon.png";
import get from "lodash/get";

const ContainPdfViewer = styled(Page)`
    height: 95%;
    justify-content: center;

    .rpv-core__text-layer {
        display: none;
    }
`;

const ContainerSpinner = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export function Doc() {
  let navigate = useNavigate();
  const {user} = useAuth();
  const {
    url,
    generate,
    transformParams,
    getDefaultValue,
    mergedValue,
  } = useGenerateCertPdf();
  const [isGenerating, setIsGenerating] = useState(true);

  const {complianceId, id, taskId} = useParams();
  const [param] = useState({complianceId, id, taskId});
  const [productImages, setProductImages] = useState<any>();

  // const printComponentRef = useRef(null);
  const [signModalVisible, setSignModalVisible] = useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState<string>();

  const {mutate: getFileUrl, data: fileData} = useGetFileUrl();

  const {data, retry: reload} = useComplianceConformityCertificate(
    useDebounce(param, 200)
  );
  const {data: productData} = useProductDetail(useDebounce(param, 200));
  const {data: complianceData} = useTaskCompliance(useDebounce(param, 200));

  const cusUpload = async (options: any) => {
    const {onSuccess, onError, file} = options;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const res: File = await upload(formData);
      onSuccess(res);
    } catch (err) {
      onError({err});
    }
  };
  const [isReady, setReady] = useState(false);

  const client = useHttp();
  const {mutate, isLoading: isCreating, isError, isSuccess} = useAddESign();

  const {mutate: upload} = useUploadFile();

  const [form] = Form.useForm();

  const handleNewData = async () => {
    const product_image = await Promise.all(
      complianceData?.productImages.map(async (item: any) => {
        const data = await client(`files/${item.uuid}`, {});
        return {
          name: item.fileName,
          uuid: item.uuid,
          url: data.url,
          status: "done",
        };
      })
    );

    await setProductImages(product_image);
    setReady(true);
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      reload();
      setSignModalVisible(false);
    } else if (!isSuccess && isError) {
      message.error("Invalid value, please try again");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  useEffect(() => {
    if (complianceData) {
      handleNewData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complianceData]);

  useEffect(() => {
    if (data && data?.eSignature?.uuid) {
      getFileUrl(data.eSignature?.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (fileData && fileData.url) {
      setTrimmedDataURL(fileData.url || "");
    }
  }, [fileData]);

  const generateDoc = async (signature?: any) => {
    setIsGenerating(true);
    const data = get(complianceData, [
      "testLines",
      (complianceData.conformityCertificateType || "DOC") === "DOC"
        ? "docGeneralInformations"
        : "cpcGeneralInformations",
    ]);
    const testLines = get(complianceData, [
      "testLines",
      (complianceData.conformityCertificateType || "DOC") === "DOC"
        ? "docTestLines"
        : "cpcTestLines",
    ]);

    const additionalInformation = get(complianceData, [
      "testLines",
      (complianceData.conformityCertificateType || "DOC") === "DOC"
        ? "docAdditionalInformation"
        : "cpcAdditionalInformation",
    ]);

    await generate(
      transformParams(
        mergedValue(data, getDefaultValue(productData, complianceData)),
        {
          additionalInformation,
          testLines,
          productPhotos: productImages.map((item: any) => item.url),
          signature,
        },
        complianceData.conformityCertificateType || "DOC"
      ),
      complianceData.conformityCertificateType || "DOC",
      false
    );

    setIsGenerating(() => {
      return false;
    });
  };

  const sigPad = React.useRef() as React.MutableRefObject<any>;

  const sign = async () => {
    const formData = form.getFieldsValue();
    const submit = async (signatureFile: any) => {
      const submitData = {
        eSignature: {
          uuid: signatureFile.uuid,
          type: "e_signature",
          fileName: signatureFile.fileName,
          size: signatureFile.size,
        },
        name: formData.name,
        title: formData.title,
        manufacturerName: formData.manufacturerName,
        manufacturerAddress: formData.manufacturerAddress,
        dateOfIssue: moment(new Date()).toISOString(),
        placeOfIssue: formData.placeOfIssue,
      };
      const response = await mutate(submitData, param.complianceId || "");
      generateDoc({
        image: signatureFile.url,
        name: response.name,
        title: response.name,
        // dateOfIssue: "2022-08-18",
        // placeOfIssue: "2222",
        dateOfIssue: response.dateOfIssue,
        placeOfIssue: response.placeOfIssue,
      });

      reload();
    };
    if (!formData.eSignatureFile || !formData.eSignatureFile.length) {
      await sigPad.current.getTrimmedCanvas().toBlob(async (blob: any) => {
        const fd = new FormData();
        fd.append("file", blob, "signature.png");
        const res: File = await upload(fd);
        await setTrimmedDataURL(res.url);
        await submit(res);
      });
    } else {
      await submit(formData.eSignatureFile[0].response);
    }
  };

  const handlePrint = () => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Conformity Certificate.pdf`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link?.parentNode?.removeChild(link);
  };

  const docProps = {
    customRequest: cusUpload,
    multiple: false,
    name: "files",

    itemRender: (
      originNode: React.ReactElement,
      file: UploadFile,
      fileList: object[],
      actions: any
    ) => {
      const patternFileExtension = /\.([0-9a-z]+)(?:[#]|$)/i;
      const extension =
        (file.fileName
          ? file.fileName.match(patternFileExtension)
          : file.name.match(patternFileExtension)) || [];

      let icon = null;
      switch (extension[1]) {
        case "doc":
        case "docx":
          icon = DocIcon;
          break;
        case "pdf":
          icon = PdfIcon;
          break;
        case "xls":
        case "xlsx":
          icon = XlsIcon;
          break;
        default:
          icon = (
            <FileImageOutlined style={{color: "#F98001", fontSize: 22}}/>
          );
      }

      return (
        <Row align="middle">
          <Col flex={"none"}>
            <Avatar
              shape="square"
              size="small"
              style={{margin: "16px 10px 5px 0px"}}
              src={icon}
            />
          </Col>
          <Col flex={`none`}>
            <div
              style={{marginTop: 12, cursor: "pointer"}}
              onClick={() => actions.download()}
            >
              {file.name || file.fileName}
            </div>
          </Col>
          <Col flex={`auto`}>
            <Button
              style={{marginTop: 10}}
              type={"link"}
              shape="circle"
              icon={<DeleteOutlined/>}
              onClick={() => actions.remove()}
            />
          </Col>
        </Row>
      );
    },
  };

  const normFile = (e: any) => {
    if (e.file.status !== "done") {
      return e && e.fileList;
    }

    const temp = e.fileList.map((item: any) => {
      if (item.response) {
        return {
          uuid: item.response.uuid,
          docType: "e_signature",
          ...item,
        };
      }
      return item;
    });

    return temp;
  };

  const isHiddenSignature = React.useCallback(() => {
    const file = form.getFieldValue("eSignatureFile");
    if (!file) return false;
    if (!file.length) return false;
    return true;
  }, [form]);

  useEffect(() => {
    if (isReady && complianceData && data) {
      const {eSignature} = data;
      if (eSignature) {
        client(`files/${eSignature.uuid}`, {}).then((file) => {
          generateDoc({
            image: file.url,
            name: data.name,
            title: data.title,
            dateOfIssue: moment(data.dateOfIssue).format("YYYY-MM-DD"),
            placeOfIssue: data.placeOfIssue,
          });
        });
      } else {
        generateDoc();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, complianceData, data]);

  return (
    <MainLayout>
      <CusPageHeader
        title={`Conformity Certificate Export`}
        onBack={() => navigate(-1)}
        extra={[
          !trimmedDataURL && user?.userRole !== "reviewer_user" && (
            <Button type="primary" onClick={() => setSignModalVisible(true)}>
              Sign PDF{" "}
            </Button>
          ),
          <Button onClick={() => handlePrint()}>Download PDF</Button>,
        ]}
      />

      <div style={{background: "#fff", height: "calc(100vh - 125px)"}}>
        {isGenerating ? (
          <ContainerSpinner>
            <Spin size="large"></Spin>
          </ContainerSpinner>
        ) : (
          <ContainPdfViewer>
            {url && (
              <Viewer
                fileUrl={url}
                renderPage={(renderPageProps) => {
                  renderPageProps.markRendered(renderPageProps.pageIndex);
                  return (
                    <div key={renderPageProps.pageIndex}>
                      {renderPageProps.canvasLayer.children}
                    </div>
                  );
                }}
              />
            )}
          </ContainPdfViewer>
        )}
      </div>
      <ModalForm
        title={`E-signature`}
        formName={`e-signature`}
        okText={`Sign`}
        loading={isCreating}
        onOk={sign}
        visible={signModalVisible}
        onCancel={() => setSignModalVisible(false)}
        form={form}
      >
        <div style={{marginBottom: 15, color: "#4E5A71"}}>
          Please make sure the accuracy of uploaded information. If you want to
          revise the information, please click ‘Cancel’.
        </div>

        <Form.Item shouldUpdate>
          {() => {
            const hidden = isHiddenSignature();
            if (hidden) return null;
            return (
              <>
                <div style={{marginBottom: 15, color: "#4E5A71"}}>
                  Sign here or upload an image of your signature:
                </div>
                <Row justify={"space-between"} gutter={[8, 8]}>
                  <Col span={24}>
                    <SignatureCanvas
                      penColor="black"
                      ref={sigPad}
                      canvasProps={{
                        width: 480,
                        height: 164,
                        className: "sigCanvas",
                      }}
                    />
                  </Col>
                </Row>
              </>
            );
          }}
        </Form.Item>
        <Row justify={"space-between"} gutter={[8, 8]}>
          <Col span={14}>
            <Form.Item
              name={"eSignatureFile"}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload {...docProps}>
                <Button type={`primary`} ghost icon={<UploadOutlined/>}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Row justify="end">
              <Typography>
                {`Date of E-sign: ${moment(new Date()).format("DD/MM/YYYY")}`}
              </Typography>
            </Row>
          </Col>
        </Row>
        <Divider/>
        <Row justify={"space-between"} gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              name={"name"}
              label={"Name"}
              rules={[{required: true, message: "Name cannot be empty"}]}
            >
              <Input placeholder={"Enter Name"}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={"title"}
              label={"Title"}
              rules={[
                {required: true, message: "Product Number cannot be empty"},
              ]}
            >
              <Input placeholder={"Enter Product Number"}/>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"space-between"} gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              name={"manufacturerName"}
              label={"Manufacturer Name"}
              rules={[
                {
                  required: true,
                  message: "Estimate Completion Date cannot be empty",
                },
              ]}
            >
              <Input
                style={{width: "100%"}}
                placeholder={"Enter Product Group"}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"} gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              name={"manufacturerAddress"}
              label={"Manufacturer Address"}
              rules={[
                {
                  required: true,
                  message: "Estimate Completion Date cannot be empty",
                },
              ]}
            >
              <Input.TextArea
                style={{width: "100%"}}
                autoSize={{minRows: 5, maxRows: 5}}
                placeholder={"Enter Product Group"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"space-between"} gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              name={"placeOfIssue"}
              label={"Place of Issue"}
              rules={[
                {
                  required: true,
                  message: "Place of Issue cannot be empty",
                },
              ]}
            >
              <Input
                style={{width: "100%"}}
                placeholder={"Enter Place of Issue"}
              />
            </Form.Item>
          </Col>
        </Row>
      </ModalForm>
    </MainLayout>
  );
}

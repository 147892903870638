import React from "react";
import styled from "@emotion/styled";
import {Card, Col, Row, Space, Typography} from "antd";
import {ReactComponent as LightIcon} from "components/Atoms/images/light.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid rgba(170, 170, 170, 0.3);
  border-radius: 16px;
  position: relative;
  h2,
  .title {
    margin-top: 0px;
    margin-bottom: 4px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
`;

const InfoCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

const StyledCardFullWidth = styled(Card)`
  width: 100%;
  background: #fafafa;
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.002em;
    text-align: left;
    color: #232b3a;
  }
  .caption {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #777777;
  }
`;

type TestPlanProps = {
  isAutoAssigned?: boolean;
};

const TestPlan = ({ isAutoAssigned }: TestPlanProps) => {
  return (
    <section>
      <Container>
        <InfoCardHeader>
          <Space>
            <h2>Test Plan</h2>
          </Space>
        </InfoCardHeader>
        <StyledCardFullWidth>
          <Row>
            <Col span={24}>
              {isAutoAssigned ? (
                <Typography>
                  Enclosed herewith the general test plan for your reference.
                  Should you have any question, please feel free to contact ICW
                  compliance team at{" "}
                  <a href="mailto:info@icw.io">info@icw.io.</a>
                </Typography>
              ) : (
                <Space align="center">
                  <LightIcon></LightIcon>
                  <Space direction="vertical" size={2}>
                    <Typography className="title">
                      Pending assign test plan
                    </Typography>
                    <Typography className="caption">
                      Reviewer will upload a test plan later. Before that,
                      please upload the compliance document as much as possible.
                    </Typography>
                  </Space>
                </Space>
              )}
            </Col>
          </Row>
          {/* <Row>
            <Col span={24}>
              <Upload
                fileList={testPlan}
                itemRender={renderFileItem}
                listType="picture"
              ></Upload>
            </Col>
          </Row> */}
        </StyledCardFullWidth>
      </Container>
    </section>
  );
};

export default TestPlan;
